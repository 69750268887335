import React, { useState } from "react";
import Layout from "../../components/Layout";
import PillHeader from "../../components/PillHeader";
import NewsletterCard from "../../components/NewsletterCard";
import { graphql, Link, StaticQuery } from "gatsby";
import Icon1 from "../../img/images/join-us-icons/Group 2193.png";
import Icon2 from "../../img/images/join-us-icons/Group 2194.png";
import Icon3 from "../../img/images/join-us-icons/Group 2253.png";
import Icon4 from "../../img/images/join-us-icons/Group 1934.png";
import Icon5 from "../../img/images/join-us-icons/Group 2195.png";
import ProgressImageOne from "../../img/images/cards/Group 2212.png";

import { JobListingApplyBox } from "./index";

const JoinUsApplyPage = () => {
  // const { data, isCommunityPage } = this.props;
  // const { opening } = data;

  // if (!(opening && opening.frontmatter)) return <Layout />;

  return (
    <Layout>
      <section id={"become-a-coach-apply-page"}>
        <div id={"become-a-coach-apply-hero"}>
          <div id={"apply-page-hero-left"}>
            <div id="course-page-breadcrumbs">
              <a href="/work-with-us">Work With Us</a>
              {/* <Link to={`/work-with-us?tab=openings`}>Current Openings</Link> */}
              {/* <a href="#">{opening.frontmatter.title}</a> */}
            </div>
            <div id={"apply-page-position-details-hero"}>
              {/* <h1>{opening.frontmatter.title}</h1>
                <h3>
                  {opening.frontmatter.job_type} |{" "}
                  {opening.frontmatter.location} |{" "}
                  {opening.frontmatter.experience_years} Experience
                </h3>
                <p>{opening.frontmatter.short_description}</p> */}
            </div>
          </div>
          <div id={"apply-page-hero-right"}>
            {/* <JobListingApplyBox
                id={"job-apply-page-form"}
                jobExperience={opening.frontmatter.experience_years}
                jobLocation={opening.frontmatter.location}
                jobTitle={opening.frontmatter.title}
                jobType={opening.frontmatter.job_type}
                isCommunityPage={isCommunityPage}
              /> */}
          </div>
        </div>

        <div id={"apply-page-content"}>
          <div id="apply-page-content-inner">
            {/* {isCommunityPage && (
                <div id={"apply-page-community-program"}>
                  <PillHeader
                    text={"Here’s why you should be at UnSchool"}
                    id={"join-us-why-heading"}
                  />

                  <div id={"community-program-why-unschool"}>
                    <div className={"community-program-why-unschool-item"}>
                      <img src={Icon1} alt="cpi-img" />
                      <div
                        className={"community-program-why-item-text-container"}
                      >
                        <h4>Make an impact</h4>
                        <p>Be a harbinger in the field of education</p>
                      </div>
                    </div>
                    <div className={"community-program-why-unschool-item"}>
                      <img src={Icon2} alt="cpi-img" />
                      <div
                        className={"community-program-why-item-text-container"}
                      >
                        <h4>Flexible work</h4>
                        <p>Work from the comfort of your home</p>
                      </div>
                    </div>
                    <div className={"community-program-why-unschool-item"}>
                      <img src={Icon3} alt="cpi-img" />
                      <div
                        className={"community-program-why-item-text-container"}
                      >
                        <h4>Growth & Learning</h4>
                        <p>Upskill yourself by learning hands-on, digitally.</p>
                      </div>
                    </div>
                    <div className={"community-program-why-unschool-item"}>
                      <img src={Icon4} alt="cpi-img" />
                      <div
                        className={"community-program-why-item-text-container"}
                      >
                        <h4>Upgrade your CV</h4>
                        <p>Relevant additions to your unique journey</p>
                      </div>
                    </div>
                    <div className={"community-program-why-unschool-item"}>
                      <img src={Icon5} alt="cpi-img" />
                      <div
                        className={"community-program-why-item-text-container"}
                      >
                        <h4>Become an influencer</h4>
                        <p>Be the leading face of change in your college</p>
                      </div>
                    </div>
                  </div>

                  <PillHeader
                    id={"progress-journey-header"}
                    text={
                      "Progress through levels and grow in your journey with us"
                    }
                  />
                  <div id={"community-progress-images"}>
                    <div className="community-progress-item">
                      <img src={ProgressImageOne} alt="cpi-img" />
                      <h4>Level 1</h4>
                      <h6>UnSchool Community Influencer</h6>
                    </div>
                    <div className="community-progress-item">
                      <img src={ProgressImageOne} alt="cpi-img" />
                      <h4>Level 2</h4>
                      <h6>UnSchool Community Leader</h6>
                    </div>
                    <div className="community-progress-item">
                      <img src={ProgressImageOne} alt="cpi-img" />
                      <h4>Level 3</h4>
                      <h6>UnSchool Community Champion</h6>
                    </div>
                  </div>
                </div>
              )} */}

            {/* <div className="job-listing-description">
                <h3>Responsibilities: </h3>
                <p>
                  <ul>
                    {(opening.frontmatter.responsibilities || []).map(
                      (responsibility) => (
                        <li key={responsibility.text}>{responsibility.text}</li>
                      )
                    )}
                  </ul>
                </p>

                <h3>Requirements:</h3>

                <p>
                  <ul>
                    {(opening.frontmatter.requirements || []).map(
                      (requirement) => (
                        <li key={requirement.text}>{requirement.text}</li>
                      )
                    )}
                  </ul>
                </p>
              </div> */}
          </div>

          <div id={"job-listing-apply-newsletter-container"}>
            <NewsletterCard />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default JoinUsApplyPage;
