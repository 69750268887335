import React from "react";
import { graphql } from "gatsby";
import { JoinUsApplyPage } from "../work-with-us/apply";

const JobOpenings = (props) => {
  return (
    <h1>Job openings</h1>
    // <JoinUsApplyPage
    //   data={props.data}
    //   isCommunityPage={props.pageContext.isCommunityPage}
    // />
  );
};

export default JobOpenings;

